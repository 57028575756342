import { render, staticRenderFns } from "./Work.vue?vue&type=template&id=27cf825c&"
import script from "./Work.vue?vue&type=script&lang=js&"
export * from "./Work.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QSeparator,QCheckbox,QForm,QInput} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QSeparator,QCheckbox,QForm,QInput})
