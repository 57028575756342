//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ActionButtons from '@/components/Profile/Settings/ActionButtons'
export default {
    props: ['dataset'],
    data() {
        return {
            work_info: this.dataset.work_info,
            work_company: this.dataset.work_company,
            share_work_info: this.dataset.share_work_info
        }
    },
    computed: {

        // The script to determine whether to unlock the save button.
        enableSave() {
            if (this.work_info != this.dataset.work_info) {
                return false;
            }
            if (this.work_company != this.dataset.work_company) {
                return false;
            }
            if (this.share_work_info != this.dataset.share_work_info) {
                return false;
            }

            return true;
        }
    },
    methods: {
        reset() {
            this.work_info = this.dataset.work_info;
            this.work_company = this.dataset.work_company;
            this.share_work_info = this.dataset.share_work_info;
        }
    },
    components: {
        ActionButtons
    }
}
